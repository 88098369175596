.page_404 {
  padding: 40px 0;
  background: #fff;
  font-family: 'Fieldwork', sans-serif;
  min-height: 100vh;
}

.page_404 img {
  width: 100%;
}

.page_404 .four_zero_four_bg {
  background-image: url('../../../assets/background/404.gif');
  height: 400px;
  margin-top: -4rem;
  background-position: center;
  background-repeat: no-repeat;
}

.page_404 h1 {
  margin: 0;
  font-size: 80px;
}

.page_404 .link_404 {
  color: #fff !important;
  padding: 10px 20px;
  background: #39ac31;
  margin: 20px 0;
  display: inline-block;
}

.page_404 .content_box_404 {
  margin-top: -50px;
}

.page_404 .text-center {
  text-align: center;
}
